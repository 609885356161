import React, { useEffect, useState } from 'react';
import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import {
  OfferMenu,
  OfferMenuItem,
  OfferWithDescription,
  RelativeSection,
  StyledH3,
  VerticalLine,
  VerticalLineSmall,
} from 'sections/Offer/Offer.styled';
import { deepTechData, hardwareData, softwareData } from 'sections/Offer/offerData';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { B2 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { ROUTE_DEEPTECH } from 'shared/paths';

export default () => {
  useEffect(() => {
    navigate(ROUTE_DEEPTECH);
  }, []);

  const [tab, setTab] = useState('#software');
  const isMd = useBreakpoint('md');

  const browserLocation = typeof window !== 'undefined' && window.location.href;

  // change tab when path changes (from footer)
  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      setTab(hash ?? '#software');
    }
    scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }, [browserLocation]);

  const handleMenuItemClick = (tabName: string) => {
    window.location.hash = tabName;
  };

  const SoftwareTab = () => (
    <>
      <Common.Div maxW="1150px">
        <B2 mb={isMd ? 4 : 8}>
          Enable digital change and build robust solutions to strengthen your market positioning, delight existing
          users, and get more profits. Create people-centric apps & systems with Us. We help our clients develop
          cutting-edge web & mobile solutions.
        </B2>
      </Common.Div>

      <Common.Div flex={'column'} gap={isMd ? '0' : '140px'}>
        {softwareData.map(({ imgSrc, title, desc, descSecondary, href }, index) => (
          <OfferWithDescription
            alignLeft={isMd || index % 2 === 0}
            imgSrc={imgSrc}
            title={title}
            desc={desc}
            href={href}
            descSecondary={descSecondary}
            isMd={isMd}
            key={title}
          />
        ))}
      </Common.Div>
    </>
  );

  const DeeptechTab = () => (
    <>
      <Common.Div maxW="1150px">
        <B2 mb={isMd ? 4 : 8}>
          Leverage AI (machine learning), blockchain, drones, objects and sounds
          <br /> indentification or clean energy tech with us as we've developed and use these all.
        </B2>
      </Common.Div>
      <Common.Div flex={'column'} gap={isMd ? '0' : '140px'}>
        {deepTechData.map(({ imgSrc, title, desc, descSecondary, href }, index) => (
          <OfferWithDescription
            alignLeft={isMd || index % 2 === 0}
            imgSrc={imgSrc}
            title={title}
            desc={desc}
            href={href}
            descSecondary={descSecondary}
            isMd={isMd}
            key={title}
          />
        ))}
      </Common.Div>
    </>
  );

  const HardwareTab = () => (
    <>
      <Common.Div maxW="1150px">
        <B2 mb={isMd ? 4 : 8}>
          Electronics design and production. With our hardware solutions we bridge the gap between IoT concepts and
          functional products. Enable vast opportunities for your business with smart technologies and connected devices
          and enter Industry 5.0 with us.
        </B2>
      </Common.Div>
      <Common.Div flex={'column'} gap={isMd ? '0' : '140px'}>
        {hardwareData.map(({ imgSrc, title, desc, descSecondary, href }, index) => (
          <OfferWithDescription
            alignLeft={isMd || index % 2 === 0}
            imgSrc={imgSrc}
            title={title}
            desc={desc}
            href={href}
            descSecondary={descSecondary}
            isMd={isMd}
            key={title}
          />
        ))}
      </Common.Div>
    </>
  );

  return (
    <CommonMargin>
      <RelativeSection title="explore our offer" variant="secondary" id={tab.toLowerCase()} isMobile={isMd}>
        <OfferMenu flex={isMd ? 'column' : 'row'} mt={isMd ? 12 : 0} position={isMd ? 'initial' : 'absolute'}>
          <VerticalLine />
          <OfferMenuItem
            isActive={tab === '#software'}
            onClick={() => handleMenuItemClick('#software')}
            isMobile={isMd}
          >
            <StyledH3 isActive={tab === '#software'}>software</StyledH3>
          </OfferMenuItem>
          {!isMd && <VerticalLineSmall />}
          <OfferMenuItem
            isActive={tab === '#deeptech'}
            onClick={() => handleMenuItemClick('#deeptech')}
            isMobile={isMd}
          >
            <StyledH3 isActive={tab === '#deeptech'}>deep tech</StyledH3>
          </OfferMenuItem>
          {!isMd && <VerticalLineSmall />}
          <OfferMenuItem
            isActive={tab === '#hardware'}
            onClick={() => handleMenuItemClick('#hardware')}
            isMobile={isMd}
          >
            <StyledH3 isActive={tab === '#hardware'}>hardware</StyledH3>
          </OfferMenuItem>
        </OfferMenu>
      </RelativeSection>
      <Section>
        {tab === '#software' && <SoftwareTab />}
        {tab === '#deeptech' && <DeeptechTab />}
        {tab === '#hardware' && <HardwareTab />}
      </Section>

      <TakeThisStepSection />
      <TrustedBySection />
    </CommonMargin>
  );
};
