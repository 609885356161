import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { B0, B2, H3 } from 'styles/Typography.styled';
import { ExploreButton } from 'components/_universal/ExploreButton';
import { navigate } from 'gatsby';

export const RelativeSection = styled(Section)<{ isMobile?: boolean; maxWidth?: number }>(
  ({ isMobile, maxWidth }) =>
    css`
      position: relative;
      padding-bottom: ${isMobile ? '48px' : '160px'};
      margin-top: 0;
      ${maxWidth && `max-width: ${maxWidth}px`};
    `
);

export const StyledTechImage = styled(Common.Svg)(
  () =>
    css`
      width: 496px;
      height: 365px;

      ${mediaQueries.sm} {
        width: 100%;
        height: auto;
      }
    `
);

export const OfferMenu = styled(Common.Div)(
  () =>
    css`
      display: flex;
      bottom: 0;
      width: calc(100% - 312px);

      ${mediaQueries.md} {
        width: calc(100% - 120px);
      }

      ${mediaQueries.sm} {
        width: calc(100%);
      }
    `
);

export const OfferMenuItem = styled(Common.Div)<{ isActive: boolean }>(
  ({ theme: { colors, fontWeight }, isActive, isMobile }) =>
    css`
      background: ${isActive ? colors.section : colors.background};
      display: flex;
      align-items: center;
      padding-left: 24px;
      height: 69px;
      flex: 1;
      padding-top: ${isMobile ? '16px' : 0};
      padding-bottom: ${isMobile ? '16px' : 0};

      h3 {
        font-weight: ${fontWeight.light};
      }
    `
);

export const StyledH3 = styled(H3)<{ isActive: boolean }>(
  ({ theme: { colors, fontSizes }, isActive }) =>
    css`
      color: ${colors.white};
      font-size: ${isActive ? fontSizes.h3 : fontSizes.b1};
    `
);

export const VerticalLine = styled(Common.Div)(
  () =>
    css`
      border: 1px solid white;
      height: 226px;
      width: 1px;
      position: absolute;
    `
);

export const VerticalLineSmall = styled(Common.Div)(
  () =>
    css`
      border: 1px solid white;
      height: 44px;
      width: 1px;
      margin-top: 25px;
    `
);

export const OfferTitle = styled(B0)(
  ({ theme: { colors } }) =>
    css`
      color: ${colors.headerText};
    `
);

interface OfferWithDescriptionProps {
  alignLeft: boolean;
  imgSrc: string;
  title: string;
  desc: string;
  descSecondary?: string;
  href: string;
  to?: string;
  isMd: boolean;
}

export const OfferWithDescription = ({
  alignLeft,
  imgSrc,
  title,
  desc,
  descSecondary,
  isMd,
  to,
}: OfferWithDescriptionProps) => (
  <Common.Div flex={isMd ? 'column' : 'row'} gap={isMd ? '16px' : '128px'} mb={6} alignItems="center">
    {alignLeft && <StyledTechImage src={imgSrc} />}
    <Common.Div maxW="500px">
      <OfferTitle mb={4}>{title}</OfferTitle>
      <StyledB2 mb={2}>{desc}</StyledB2>
      {descSecondary && <StyledB2 mb={2}>{descSecondary}</StyledB2>}
      {to && <ExploreButton onClick={() => navigate(to)} />}
    </Common.Div>
    {!alignLeft && <StyledTechImage src={imgSrc} />}
  </Common.Div>
);

const StyledB2 = styled(B2)`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.79;
  white-space: pre-wrap;
`;
